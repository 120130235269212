import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CustomFilter from 'components/CustomFilter';
import useStyles from './styles';
import useSubscriptions from 'hooks/services/useSubscriptions';
import useSubscribeEntitlement from 'hooks/services/useSubscribeEntitlement';
import SearchBox from 'components/SearchBox';
import Table from 'components/ReactTable';
import SubscriptionStatusText from 'components/SubscriptionStatesText';
import LoaderSpinner from 'components/LoaderSpinner';
import { selectOrganization } from 'state/auth/selectors';
import { SUBSCRIPTION_STATUS } from 'utils/constants';

const {
  REACT_APP_AWS_MARKETPLACE_SUBSCRIPTIONS_MANAGEMENT,
  REACT_APP_AZURE_MARKETPLACE_SUBSCRIPTIONS_MANAGEMENT,
  REACT_APP_AZURE_MARKETPLACE_SUBSCRIPTIONS_ADD,
  REACT_APP_AWS_MARKETPLACE_SUBSCRIPTIONS_ADD,
  REACT_APP_APP_INSIGHTS_UI_URL,
} = process.env;

const Subscriptions = () => {
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const organizationId = useSelector(selectOrganization);
  const { data, isLoading, isError, fetchNextPage, hasNextPage } =
    useSubscriptions(organizationId);
  const { mutateAsync: subscribeEntitlement } = useSubscribeEntitlement();

  const [flattenedSubscriptions, setFlattenedSubscriptions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    SUBSCRIPTION_STATUS.PENDING
  );
  const [dialogMessage, setDialogMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const token = searchParams.get('token');
  const vendor = searchParams.get('vendor');
  const offerType = searchParams.get('offer-type');

  useEffect(() => {
    if (data) {
      const flattened = data.pages.flatMap((page) => page.data?.subscriptions);
      setFlattenedSubscriptions(flattened);
      setAllSubscriptions(flattened);
    }
  }, [data]);

  useEffect(() => {
    if (!!token && !!vendor) {
      setOpenDialog(true);
      subscribe();
    }
  }, [token, vendor]);

  const subscribe = async () => {
    const payload = {
      organizationId,
      provider: vendor,
      subscriptionData: {
        token,
        offerType,
      },
    };

    try {
      const response = await subscribeEntitlement(payload);
      if (!!response.data?.subscription?.id) {
        setSubscriptionStatus(SUBSCRIPTION_STATUS.SUCCESS);
        setDialogMessage('Successfully subscribed!');
      } else {
        setSubscriptionStatus(SUBSCRIPTION_STATUS.FAILURE);
        setDialogMessage(
          'Subscription failed. Please contact support or retry.'
        );
      }
    } catch (error) {
      setSubscriptionStatus(SUBSCRIPTION_STATUS.FAILURE);
      setDialogMessage(`Something went wrong: ${error.message}`);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    history.push(path);
  };

  // Search functionality
  const filterSubscriptions = (event) => {
    const searchValue = event.target.value.toLowerCase();
    const filtered = allSubscriptions.filter((item) => {
      const formattedSubscriptionId = item.subscriptionId
        .toString()
        .padStart(6, '0');
      const normalizedSubscriptionId = item.subscriptionId
        .toString()
        .toLowerCase();
      return (
        item.provider.toLowerCase().includes(searchValue) ||
        item.managementAccess.toLowerCase().includes(searchValue) ||
        formattedSubscriptionId === searchValue ||
        normalizedSubscriptionId.includes(searchValue.trim()) ||
        normalizedSubscriptionId.includes(searchValue)
      );
    });
    setFlattenedSubscriptions(filtered);
  };

  const statusData = [
    { id: 1, name: 'ACTIVE' },
    { id: 2, name: 'DEACTIVATED' },
    { id: 2, name: 'PENDING_DEACTIVATION' },
    { id: 2, name: 'PENDING_ACTIVATION' },
  ];

  const [filter, setFilter] = useState({
    status: '',
    userPriority: 'All',
  });

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Provider',
        accessor: 'provider',
      },
      {
        Header: 'Status',
        accessor: 'state',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return <SubscriptionStatusText priority={values.state} />;
        },
      },
      {
        Header: 'Manage Provider Subscription',
        accessor: 'managementAccess',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          let managementUrl = '';
          switch (values.provider) {
            case 'AWS':
              managementUrl =
                REACT_APP_AWS_MARKETPLACE_SUBSCRIPTIONS_MANAGEMENT;
              break;
            case 'AZURE':
              managementUrl =
                REACT_APP_AZURE_MARKETPLACE_SUBSCRIPTIONS_MANAGEMENT;
            default:
              break;
          }
          return (
            <a href={managementUrl} target="_blank" rel="noopener noreferrer">
              {managementUrl}
            </a>
          );
        },
      },
    ],
    []
  );

  return (
    <Grid container xs={12} sx={classes.container}>
      <Typography xs={12} sx={classes.header}>
        Subscriptions
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        {/* <Grid item xs={12} sm={6} md={4}>
          <SearchBox
            placeholder="Search..."
            onchangefunc={filterSubscriptions}
          />
        </Grid> */}
        <Stack>
          <Grid item sx={classes.status}>
            <CustomFilter
              placeholder="Select status"
              selectItems={statusData}
              handleChange={handleStatusChange}
              selectedValue={filter?.status}
            />
          </Grid>
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="end">
          <Grid item>
            <Button
              variant="contained"
              sx={classes.addButton}
              startIcon={<AddIcon />}
              href={REACT_APP_AZURE_MARKETPLACE_SUBSCRIPTIONS_ADD}
              target="_blank"
            >
              Add Azure subscription
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              sx={classes.addButton}
              startIcon={<AddIcon />}
              href={REACT_APP_AWS_MARKETPLACE_SUBSCRIPTIONS_ADD}
              target="_blank"
            >
              Add AWS subscription
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: 'rgb(15, 94, 247)' }}
              color="primary"
              href={`${REACT_APP_APP_INSIGHTS_UI_URL}/clusters`}
              endIcon={<OpenInNewIcon />}
              target="_blank"
            >
              Go to App Insights
            </Button>
          </Grid>
        </Stack>
      </Stack>
      <Grid container>
        <Grid item xs={12}>
          <Table
            columns={columns}
            tableMaxHeight="calc(100vh - 240px)"
            data={flattenedSubscriptions || []}
          />
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {subscriptionStatus === SUBSCRIPTION_STATUS.PENDING && (
            <>
              <Typography sx={classes.dialogText}>
                Subscription is in progress. Please wait...
              </Typography>
            </>
          )}
          {subscriptionStatus === SUBSCRIPTION_STATUS.SUCCESS && (
            <>
              <Typography sx={classes.dialogText}>{dialogMessage}</Typography>
            </>
          )}
          {subscriptionStatus === SUBSCRIPTION_STATUS.FAILURE && (
            <>
              <Typography sx={classes.dialogText}>{dialogMessage}</Typography>
            </>
          )}
        </DialogTitle>
        {subscriptionStatus === SUBSCRIPTION_STATUS.PENDING && (
          <Grid sx={classes.loading}>
            <LoaderSpinner
              width="30px"
              height="50%"
              type="ThreeDots"
              color="rgb(0 24 71)"
            />
          </Grid>
        )}
        {subscriptionStatus !== SUBSCRIPTION_STATUS.PENDING && (
          <Grid sx={classes.centerbox}>
            <DialogActions>
              <Button
                sx={classes.leaveButton}
                onClick={handleCloseDialog}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </Grid>
        )}
      </Dialog>
    </Grid>
  );
};

export default Subscriptions;
